import styled from "styled-components";
import { Link } from "react-router-dom";

export const SectionHeader = styled.p`
  font-size: 24px;
  font-weight: 400;
  font-style: italic;
`;

const HomeLinkStyle = styled(Link)`
  position: fixed;
  bottom: 0;
  right: 20%;
  margin-bottom: 20px;
  background: var(--primary-color-text);
  color: var(--primary-color-background);
  font-size: 24px;
`;

export const HomeLink = () => <HomeLinkStyle to="/">home</HomeLinkStyle>;

export const Block = styled.div`
  padding-bottom: 20px;
`;
