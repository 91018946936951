import styled from "styled-components";
import { HomeLink, SectionHeader, Block } from "./Reusables";
import clips from "../data/clips.json";

const PublicationTitle = styled.h3`
  margin: 5px;
`;

const ArticleList = styled.ul`
  margin: 5px;
  list-style-type: none;
  padding-inline-start: 20px;
`;

const Headline = styled.a`
  text-decoration: none;
  margin: 2px;
  &:before {
    content: "~";
    margin-right: 10px;
    display: inline-block;
  }
`;

const Li = styled.li`
  margin: 4px;
`;

const clipGenerator = (domain) => {
  const filteredList = clips.filter((clip) => clip.url.startsWith(domain));
  return filteredList.map((clip) => {
    return (
      <Li key={clip.url}>
        <Headline href={clip.url}>{clip.headline}</Headline>
      </Li>
    );
  });
};

const Clips = () => {
  return (
    <>
      <SectionHeader>here are some pieces I've written</SectionHeader>

      <Block>
        <PublicationTitle>Defector</PublicationTitle>
        <ArticleList>{clipGenerator("https://defector.com")}</ArticleList>
      </Block>

      <Block>
        <PublicationTitle>BNet</PublicationTitle>
        <ArticleList>{clipGenerator("https://bnet.substack.com")}</ArticleList>
      </Block>

      <Block>
        <PublicationTitle>nymag/Select All</PublicationTitle>
        <ArticleList>{clipGenerator("https://nymag.com")}</ArticleList>
      </Block>

      <Block>
        <PublicationTitle>New York Times</PublicationTitle>
        <ArticleList>{clipGenerator("https://www.nytimes.com")}</ArticleList>
      </Block>

      <Block>
        <PublicationTitle>Bloomberg News</PublicationTitle>
        <ArticleList>{clipGenerator("https://www.bloomberg.com")}</ArticleList>
      </Block>

      <Block>
        <PublicationTitle>VICE</PublicationTitle>
        <ArticleList>{clipGenerator("https://www.vice.com")}</ArticleList>
      </Block>

      <Block>
        <PublicationTitle>The Awl</PublicationTitle>
        <ArticleList>{clipGenerator("https://www.theawl.com")}</ArticleList>
      </Block>

      <Block>
        <PublicationTitle>Business Insider</PublicationTitle>
        <ArticleList>
          {clipGenerator("https://www.businessinsider.com")}
        </ArticleList>
      </Block>

      <Block>
        <PublicationTitle>GQ</PublicationTitle>
        <ArticleList>{clipGenerator("https://www.gq.com")}</ArticleList>
      </Block>

      <Block>
        <PublicationTitle>Vulture</PublicationTitle>
        <ArticleList>{clipGenerator("https://www.vulture.com")}</ArticleList>
      </Block>

      <Block>
        <PublicationTitle>The Atlantic</PublicationTitle>
        <ArticleList>
          {clipGenerator("https://www.theatlantic.com")}
        </ArticleList>
      </Block>

      <Block>
        <PublicationTitle>Radio/Podcast appearances</PublicationTitle>
        <ArticleList>
          {clipGenerator("https://www.wbur.org")}
          {clipGenerator("https://www.wnyc.org")}
          {clipGenerator("https://gimeltmedia.com")}
        </ArticleList>
      </Block>

      <HomeLink />
    </>
  );
};

export default Clips;
