import { createGlobalStyle } from "styled-components";

const darkMode =
  window.matchMedia &&
  window.matchMedia("(prefers-color-scheme: dark)").matches;

const GlobalStyles = createGlobalStyle`
  html {
    --primary-color-text: black;
    --primary-color-background: white;
  }
  
  ${
    darkMode &&
    `
    html {
      --primary-color-text: white;
      --primary-color-background: black;
    }`
  }
  
  body {
    background: var(--primary-color-background);
    color: var(--primary-color-text);
    height: 100%;
    margin: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-family: "Roboto Condensed";
  }
  
  @media only screen and (max-width: 600px) {
      body {
        margin: 25px;
      }
    }
  
  a {
    color: var(--primary-color-text);
    display: inline-flex;
    text-decoration: none;
    width: fit-content;
    width: -moz-fit-content;
    padding-left: 5px;
    padding-right: 5px;
    height: auto;
  }
  
  a:hover {
    color: var(--primary-color-background);
    background: var(--primary-color-text);
  }
`;

export default GlobalStyles;
