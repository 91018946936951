import styled from "styled-components";
import { HomeLink, SectionHeader } from "./Reusables";

const ContactLink = styled.div`
  font-size: 20px;
  font-weight: bold;
  padding-bottom: 20px;
`;

const Contact = () => {
  return (
    <>
      <SectionHeader>ways to get in touch</SectionHeader>

      <ContactLink>
        <a href="mailto:brian@feldmanbrian.com">brian@feldmanbrian.com</a>
      </ContactLink>

      <ContactLink>
        <a href="http://www.linkedin.com/in/feldmanbrian">LinkedIn</a>
      </ContactLink>

      <HomeLink />
    </>
  );
};

export default Contact;

// UNUSED
// <ContactLink>
//   <a href='http://www.twitter.com/bafeldman'>Twitter</a>
// </ContactLink>
//
// <ContactLink>
//   <a href='http://github.com/bfeldman'>GitHub</a>
// </ContactLink>
