import styled from "styled-components";
import { Link } from "react-router-dom";
import { SectionHeader } from "./Reusables";

const SectionLink = styled.h2``;

const Home = () => {
  return (
    <>
      <SectionHeader>welcome to the site</SectionHeader>
      <SectionLink>
        <Link to="/contact">contact</Link>
      </SectionLink>
    </>
  );
};

export default Home;
