import { Routes, Route, Link } from "react-router-dom";
import styled from "styled-components";

import GlobalStyles from "../GlobalStyles";
import Home from "../components/Home";
import Contact from "../components/Contact";
import Clips from "../components/Clips";
import Code from "../components/Code";

import MediaList from "../components/MediaList";

const BigNameContainer = styled.div`
  text-transform: uppercase;
  font-size: 40px;
  font-weight: bold;
  width: fit-content;
  width: -moz-fit-content;
  background-color: var(--primary-color-text);
`;

const BigName = styled(Link)`
  color: var(--primary-color-background);
`;

const PersonalSiteContainer = () => {
  return (
    <>
      <GlobalStyles />
      <BigNameContainer>
        <BigName to="/">Brian Feldman</BigName>
      </BigNameContainer>

      <Routes>
        <Route index element={<Home />} />
        <Route path="contact" element={<Contact />} />
        <Route path="clips" element={<Clips />} />
        <Route path="code" element={<Code />} />

        <Route path="years/*" element={<MediaList />} />
      </Routes>
    </>
  );
};

export default PersonalSiteContainer;
