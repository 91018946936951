import styled from "styled-components";
import { HomeLink, SectionHeader, Block } from "./Reusables";

const ProjectTitle = styled.h3`
  margin: 5px;
`;

const FeatureList = styled.ul`
  margin: 5px;
  list-style-type: none;
  padding-inline-start: 20px;
`;

const Feature = styled.li`
  text-decoration: none;
  margin: 2px;
  &:before {
    content: "~";
    margin-right: 10px;
    display: inline-block;
  }
`;

const Contact = () => {
  return (
    <>
      <SectionHeader>
        here are links to some coding projects of mine
      </SectionHeader>

      <Block>
        <ProjectTitle>
          <a href="https://github.com/bfeldman/photobooth-frontend">
            Boto Phooth
          </a>
        </ProjectTitle>
        <FeatureList>
          <Feature>
            Built a tool for capturing and editing photos in a web browser using
            React and HTML &lt;canvas&gt;
          </Feature>
          <Feature>
            Enabled basic social networking features such as profile pages,
            comments, and privacy setting using a Rails backend and JSON Web
            Tokens
          </Feature>
          <Feature>
            Styled with a clean, concise design using Semantic UI
          </Feature>
        </FeatureList>
      </Block>

      <Block>
        <ProjectTitle>
          <a href="https://www.instagram.com/ar/1466088010210630/">
            Which Catherine Are You?
          </a>
        </ProjectTitle>
        <FeatureList>
          <Feature>
            Augmented reality lens that's tribute to my dog, built in SparkAR
          </Feature>
        </FeatureList>
      </Block>

      <Block>
        <ProjectTitle>
          <a href="https://github.com/bfeldman/Plant-Project-FE">
            Plant Collector
          </a>
        </ProjectTitle>
        <FeatureList>
          <Feature>
            Populated search results dynamically using the Trefle.io API
          </Feature>
          <Feature>Used React to build multi-select functionality</Feature>
          <Feature>
            Built a modal view from scratch to display more detailed information
          </Feature>
        </FeatureList>
      </Block>

      <Block>
        <ProjectTitle>
          <a href="https://github.com/bfeldman/completionist-frontend">
            Completionist
          </a>
        </ProjectTitle>
        <FeatureList>
          <Feature>
            A full-featured, single-page to-do list app built with vanilla
            Javascript
          </Feature>
          <Feature>
            Implemented detailed tagging features and methods for sorting and
            filtering
          </Feature>
          <Feature>
            Created a fully automated alert pop-up for imminent deadlines
          </Feature>
        </FeatureList>
      </Block>

      <Block>
        <ProjectTitle>
          <a href="https://github.com/bfeldman/job-tracker-app">Job Tracker</a>
        </ProjectTitle>
        <FeatureList>
          <Feature>
            Rails-based app that allows users to query a job-search API with a
            number of parameters
          </Feature>
          <Feature>
            Allows users to create and edit a professional profile
          </Feature>
          <Feature>
            Ultralight social-networking feature to let users see how popular
            certain listings are
          </Feature>
        </FeatureList>
      </Block>

      <Block>
        <ProjectTitle>
          <a href="https://github.com/bfeldman/mod1_project">Movie App</a>
        </ProjectTitle>
        <FeatureList>
          <Feature>
            A Ruby-based CLI for querying the Open Movie Database
          </Feature>
          <Feature>Lets users build and edit lists of movie</Feature>
          <Feature>
            Used ImageMagick to render image files in a terminal interface
          </Feature>
        </FeatureList>
      </Block>

      <HomeLink />
    </>
  );
};

export default Contact;
